import React from 'react'
import { Link as GLink } from 'gatsby'
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { Container, Box, Flex } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import PoweredByRelaxSeller from '@solid-ui-components/PoweredByRelaxSeller'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'
import getImage from '@solid-ui-components/utils/getImage'

const styles = {
  wrapper: {
    position: `relative`,
    bg: `footerBg`
  },
  footer: {
    flexDirection: [`column-reverse`, `row`],
    justifyContent: `center`,
    alignItems: [`center`, `flex-start`],
    py: 3
  }
}

const FooterBlock01 = ({ content: { collection } }) => {
  return (
    <Box sx={styles.wrapper}>
      <Container px='4'>
        <Flex sx={styles.footer}>
          <Box sx={{ minWidth: 200 }}>
            <Box mb={[2, 0]} mt='1'>
              Tüm Hakları Saklıdır. © 2021{' '}
              <a
                style={{ textDecoration: 'none' }}
                href='https://www.relaxseller.com'
              >
                RelaxSeller {" "}
              </a>
            </Box>
          </Box>
          {collection?.map(
            ({ buttons }, index) =>
              buttons && (
                <Box key={`item-${index}`} mt='1'>
                  <ContentButtons
                    content={buttons}
                    variant='vertical'
                    wrapperStyles={{ flexDirection: [null, `row`] }}
                  />
                </Box>
              )
          )}
        </Flex>
      </Container>
    </Box>
  )
}

FooterBlock01.defaultProps = {
  menuJustify: `flex-end`
}

export default WithDefaultContent(FooterBlock01)
